<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <card class='pb-5'>
          <template v-slot:headerTitle>
            <h3 class="card-title font-weight-bold" style='font-size: 25px'>Detalles de la Cuenta</h3>
          </template>
          <h2 class='pl-4 pt-3' style='font-size: 1rem;'>Cuenta: {{ cuenta.nombre }}</h2>
          <div class='p-5'>
            <div class='row'>
              <h4 class="col-4 p-0">
                FECHA
              </h4>
              <h4 class="col-2 p-0" style="text-align: end">
                REFERENCIA
              </h4>
              <h4 class="col-2 p-0" style="text-align: end">
                DEBITO
              </h4>
              <h4 class="col-2 p-0" style="text-align: end">
                CREDITO
              </h4>
              <h4 class="col-2 p-0" style="text-align: end">
                SALDO
              </h4>
            </div>
            <div class='mt-5 row' style='border-bottom: 2px solid;'>
              <h3 class="card-title font-weight-bold mb-0 p-0" style='font-size: 16px'>Cuentas por cobrar ({{ cuenta.Tipo }})</h3>
            </div>
            <div class='border-bottom py-2 row'>
              <h3 class="card-title font-weight-bold mb-0 col-6 p-0" style='font-size: 13px; color: gray'>Saldo de
                apertura</h3>
              <p class="card-title mb-0 p-0 col-6" style='font-size: 13px; color: gray; text-align: end;'>$ 0.00</p>
            </div>
            <div class='border-bottom py-1 row' v-for='data in cuenta.movimientos'
              :key='data.idcuentaCuenta'>
              <p class="card-title col-4 p-0 mb-0" style='font-size: 13px; color: gray'>{{
                $moment.tz(data.fecha, "America/Bogota").format("MM-DD-YYYY") }}</p>
              <p class="card-title col-2 p-0 mb-0" @click.prevent='$router.push(`/helex/Erp/Contabilidad/RegistrosContables/EditarRegistro/${data.movimientoId
}`)' style='font-size: 13px; color: gray; text-align: end;cursor: pointer'><span style='width: fit-content;border-bottom: 1px dotted'>{{
                data.referencia }}</span></p>
              <p class="card-title col-2 p-0 mb-0" style='font-size: 13px; color: gray; text-align: end;'>$ {{
                data.debito }}</p>
              <p class="card-title col-2 p-0 mb-0" style='font-size: 13px; color: gray; text-align: end;'>$ {{
                data.credito }}</p>
              <p class="card-title col-2 p-0 mb-0" style='font-size: 13px; color: gray; text-align: end;'>$ {{
                data.credito ||
                data.debito }}</p>
            </div>
            <div class='border-bottom py-2 row'>
              <p class="card-title font-weight-bold mb-0 col-6 p-0" style='font-size: 13px; color: gray'>Totales y
                saldo
                Final</p>
              <p class="card-title col-2 p-0 mb-0" style='font-size: 13px; color: gray; text-align: end;'>$ {{
                sumadebito }}</p>
              <p class="card-title col-2 p-0 mb-0" style='font-size: 13px; color: gray; text-align: end;'>$ {{
                sumacredito }}</p>
              <p class="card-title col-2 p-0 mb-0" style='font-size: 13px; color: gray; text-align: end;'>$ {{
                cuenta.saldo }}</p>
            </div>
            <div class='border-bottom py-2 row'>
              <h3 class="card-title font-weight-bold mb-0 col-6 p-0" style='font-size: 13px; color: gray'>Cambio de
                saldo</h3>
              <p class="card-title mb-0 p-0 col-6" style='font-size: 13px; color: gray; text-align: end;'>$ {{
                sumadebito -
                sumacredito }}</p>
            </div>
          </div>
          <div class='d-flex px-3 justify-content-end'>
            <vs-button size="large" dark shadow
              @click.prevent="$router.push('/helex/Erp/Contabilidad/RegistrosContables/Cuentas')">Regresar</vs-button>
            <vs-button size="large"
              @click.prevent="$router.push(`/helex/Erp/Contabilidad/RegistrosContables/EditarCuenta/${cuenta.Id}`)">Editar</vs-button>
          </div>
        </card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import { core } from '../../../../../../config/pluginInit';

  export default {
    data: () => ({
      cuenta: {},
      sumadebito: 0,
      sumacredito: 0,
    }),
    async mounted() {
      core.index()
      await this.getData()
    },
    methods: {
      async getData() {
        let cuentas = await this.$store.dispatch('hl_get', {
          path: `DESubCuenta/consultarMovimientosSubCuenta/${this.$route.params.id}`,
        })
        this.cuenta = cuentas.data
        this.sumadebito = this.cuenta.movimientos.reduce((acc, item) => acc + item.debito, 0)
        this.sumacredito = this.cuenta.movimientos.reduce((acc, item) => acc + item.credito, 0)
      }
    }
  }
</script>